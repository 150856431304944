.congratulations-page-background {
  background-image: url(../../assets/images/login-bg-desktop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 2328px) {
  .congratulations-page-background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    height: 57.5vw;
    //height: 100vw;
    width: 100%;
    z-index: -1;
  }
}

// @media only screen and (max-width: 991px) {
//   .congratulations-page-background {
//     background-image: url(../../assets/images/login-bg-mobile.jpg);
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center bottom;
//     height: 140vw;
//     width: 100%;
//     z-index: -1;
//     // height: 270vw;
//   }
// }


@media only screen and (max-width: 991px) {
  .congratulations-page-background {
    background-image: url(../../assets/images/login-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    padding-bottom: 100%;

    width: 100%;
    z-index: -1;
    // height: 270vw;
  }
}

@media only screen and (max-width: 767px) {
  .congratulations-page-background {
    padding-bottom: 230%;
  }
}
