.input-currency {
  position: relative;
}

.input-currency input[type="number"] {
  padding-left: 30px;
}

.input-currency div {
  position: absolute;
  left: 5px;
  top: 4px;
  padding: 9px 8px;
  color: #aaa;
  transition: 0.3s;
  width: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
