.input-mileage {
  position: relative;
}

.input-mileage input[type="number"] {
  padding-left: 15px;
  padding-right: 60px;
}

.input-mileage div {
  position: absolute;
  right: 0px;
  top: 4px;
  padding: 9px 8px;
  color: #aaa;
  transition: 0.3s;
  width: 60px;
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
