.login-page-background {
  background-image: url(../../assets/images/login-bg-desktop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 2328px) {
  .login-page-background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    height: 57.5vw;
    //height: 100vw;
    width: 100%;
    z-index: -1;
  }
}

@media only screen and (max-width: 991px) {
  .login-page-background {
    background-image: url(../../assets/images/login-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
    height: 140vw;
    width: 100%;
    z-index: -1;
  }
}

//@media only screen and (max-width: 991px) {
//  .login-page-background  {
//    background-image: url(/img/home-bg-mobile.jpg);
//    background-repeat: no-repeat;
//    background-size: contain;
//    background-position: center bottom;
//    height: calc(100vh - 107px);
//    width: 100%;
//    z-index: -1;
//  }
//}
//
@media only screen and (max-width: 575px) {
  .login-page-background {
    & .h3 {
      font-size: 1.25rem !important;
    }

    & label {
      font-size: 0.75rem !important;
    }

    //background-image: url(/img/home-bg-mobile.jpg);
    //background-repeat: no-repeat;
    //background-size: contain;
    //background-position: center bottom;
    //height: 140vw;
    //width: 100%;
    //z-index: -1;
  }

  .footer-fix-home {
    position: relative !important;
  }

  .footer-fix-inner {
    position: relative !important;
  }
}

.login-auth-error {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    position: relative;
    padding: 0.25rem 1.25rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 0rem;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    list-style: none;
  }
}

.btn-outline-light:hover, .btn-primary:hover, .btn-outline-primary:hover{
  background-color: lightgrey;
  border-radius: 0px;
  border: none;
}

.btn-outline-primary:hover{
  color: black;

}

.btn-outline-primary{
  color: white;

}

.btn-outline-light, .btn-primary, .btn-outline-primary{
  background-color: #000000;
  border-radius: 0px;
  border: none;
}

.nav-link.active{
  background-color: #000000 !important;
  border: 3px solid #000000 !important;
}