.vw-logo {
  position: absolute;
  background-image: url(../../assets/images/vw-logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 100px;
  width: 100vw;
  bottom: 10px;
  left: 0;
  z-index: 0;
  pointer-events: none;

  @media only screen and (max-width: 575px) {
    // top: 100px;
    // left: 0;
  }
}
